const schema = (photoEndpoint, thumbsEndpoint = null) => ({
  types: [
    {
      name: 'photo',
      label: 'T_GENERAL_MEDIA_PHOTO',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            alt: {
              description: 'T_GENERAL_ALT',
            },
          },
        },
        photo: {
          type: 'image',
          description: 'T_GENERAL_PHOTO',
          endpoint: photoEndpoint,
          thumbs: thumbsEndpoint && {
            endpoint: thumbsEndpoint,
          },
          validate: ['maxSize'],
        },
        stat: {
          description: 'T_GENERAL_PUBLICATION',
        },
      },
    },
    {
      name: 'youtube',
      label: 'T_GENERAL_MEDIA_YOUTUBE',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            alt: {
              description: 'T_GENERAL_ALT',
            },
          },
        },
        photo: {
          type: 'image',
          description: 'T_GENERAL_VIDEO_MASK',
          endpoint: photoEndpoint,
          thumbs: thumbsEndpoint && {
            endpoint: thumbsEndpoint,
          },
          validate: ['maxSize'],
        },
        youtube: {
          type: 'youtube',
          description: 'T_GENERAL_YOUTUBE_CODE',
        },
        stat: {
          description: 'T_GENERAL_PUBLICATION',
        },
      },
    },
  ],
})

export default schema
