import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { FirstPage, LastPage } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))

export const PaginationActions = props => {
  const classes = useStyles()

  const { count, page, rowsPerPage, onChangePage } = props

  const pages = useMemo(() => Math.ceil(count / rowsPerPage), [
    count,
    rowsPerPage,
  ])

  const handleFirstClick = e => {
    onChangePage(e, 0)
  }

  const handlePageClick = (e, value) => {
    onChangePage(e, value - 1)
  }

  const handleLastClick = e => {
    onChangePage(e, Math.max(0, pages - 1))
  }

  return pages ? (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstClick}
        disabled={page === 0}
        aria-label={translate('T_TABLE_FIRST_PAGE')}
        title={translate('T_TABLE_FIRST_PAGE')}
      >
        <FirstPage />
      </IconButton>
      <Pagination
        count={pages}
        page={page + 1}
        onChange={handlePageClick}
        color="primary"
      />
      <IconButton
        onClick={handleLastClick}
        disabled={page >= pages - 1}
        aria-label={translate('T_TABLE_LAST_PAGE')}
        title={translate('T_TABLE_LAST_PAGE')}
      >
        <LastPage />
      </IconButton>
    </div>
  ) : null
}

PaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}
