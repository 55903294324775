import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import {
  ListOutlined,
  ChevronLeftOutlined,
  ChevronRightOutlined,
} from '@material-ui/icons'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { CustomButton } from 'core/components/CustomButton'
import { BlankForm, NotFound } from 'core/pages'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { EditHeader } from 'core/components/EditHeader'
import { translate } from 'core/_helpers/translate'
import { boolean } from 'core/_helpers/boolean'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    '&> *:first-child': {
      marginRight: 40,
    },
    '&> *:last-child': {
      marginBottom: 32,
      backgroundColor: theme.elements.tab.body.background,
      borderRadius: 4,
      padding: 18,
      alignSelf: 'flex-start',
    },
  },
  bottomNav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.elements.tab.body.background,
    padding: 18,
    width: 'calc(100% + 64px)',
    marginLeft: -32,
    marginBottom: -32,
  },
  bottomNavBackButton: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.info.main,
  },
  bottomNavBackButtonTitle: {
    marginLeft: 8,
  },
  bottomNavRight: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomNavRightNextButton: {
    marginLeft: 5,
  },
  formButtons: {
    display: 'flex',
    alignItems: 'center',
    '&> *': {
      margin: '0 5px',
    },
  },
  containerWithPadding: {
    padding: theme.spacing(4),
  },
}))

export const ResourceMediaEdit = ({
  uuid,
  definitions,
  schema,
  mediaResourceProperty = 'photo',
  editPath,
  parentEditPath,
  parentShowPath,
  formWidth = 600,
}) => {
  const iri = useMemo(() => `${schema.endpoint}/${uuid}`, [
    schema.endpoint,
    uuid,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const [refs, setRefs] = useState({
    buttonContainer: null,
    cropperContainer: null,
  })

  const onButtonContainerRef = useCallback(
    element => {
      setRefs(state => ({ ...state, buttonContainer: element }))
    },
    [setRefs]
  )

  const onCropperContainerRef = useCallback(
    element => {
      setRefs(state => ({ ...state, cropperContainer: element }))
    },
    [setRefs]
  )

  const collectionPath =
    resource &&
    parentShowPath.replace(
      ':id',
      resource.parent?.[process.env.REACT_APP_RESOURCE_ID]
    )

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <div className={classes.containerWithPadding}>
      <EditHeader
        resource={resource.parent}
        editPath={parentEditPath}
        wrapWithTitle={true}
      />
      <div className={classes.mainContainer}>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={schema.resource}
          iri={iri}
          method="PUT"
          url={iri}
          collectionPath={collectionPath}
          storeCollectionId={resource.parent?.['@id']}
          fetchCompareResource={boolean(
            process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
          )}
          fieldsFullWidth={false}
          width={formWidth}
          buttonContainerRef={refs.buttonContainer}
          fieldNodeRefs={{ [mediaResourceProperty]: refs.cropperContainer }}
          showCancelButton={false}
          key={resource['@id']}
        />
        <div
          ref={onCropperContainerRef}
          style={{ width: `calc(100% - ${formWidth + 40}px)` }}
          className="MuiPaper-elevation1"
        ></div>
      </div>
      <div className={classes.bottomNav}>
        <div>
          {resource.previousItemUuid && (
            <CustomButton
              title={translate('T_GENERAL_PREVIOUS')}
              path={editPath.replace(':id', resource.previousItemUuid)}
              size="small"
              color="default"
              startIcon={<ChevronLeftOutlined />}
            />
          )}
        </div>
        <div>
          <Link to={collectionPath} className={classes.bottomNavBackButton}>
            <ListOutlined />
            <span className={classes.bottomNavBackButtonTitle}>
              {translate('T_GENERAL_BACK_TO_LIST')}
            </span>
          </Link>
        </div>
        <div className={classes.bottomNavRight}>
          <div ref={onButtonContainerRef} className={classes.formButtons}></div>
          <div className={classes.bottomNavRightNextButton}>
            {resource.nextItemUuid && (
              <CustomButton
                title={translate('T_GENERAL_NEXT')}
                path={editPath.replace(':id', resource.nextItemUuid)}
                size="small"
                color="default"
                endIcon={<ChevronRightOutlined />}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

ResourceMediaEdit.propTypes = {
  uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  definitions: PropTypes.object.isRequired,
  schema: PropTypes.shape({
    endpoint: PropTypes.string.isRequired,
    resource: PropTypes.shape({
      definition: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  mediaResourceProperty: PropTypes.string,
  editPath: PropTypes.string.isRequired,
  parentEditPath: PropTypes.string.isRequired,
  parentShowPath: PropTypes.string.isRequired,
  formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
