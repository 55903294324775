import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { ListItem } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  tree: {
    display: 'flex',
    alignItems: 'center',
    color: theme.elements.menu.text,
  },
  withoutChildren: {
    marginLeft: 2,
  },
  toggle: {
    cursor: 'pointer',
    border: '1px solid',
    borderRadius: '50%',
    display: 'flex',
    '& svg': {
      padding: 4,
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.elements.menu.text,
    '& *': {
      color: theme.elements.menu.text,
    },
    flexGrow: 1,
  },
  inner: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 6,
    '&:hover': {
      backgroundColor: theme.elements.menu.hover,
    },
  },
  active: {
    color: theme.elements.menu.active,
    '& *': {
      color: theme.elements.menu.active,
    },
  },
}))

export const PageTree = ({ item, editPath, indent = 0 }) => {
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(state => !state)
  }

  const classes = useStyles()

  const title = useMemo(
    () => item.translations[process.env.REACT_APP_LOCALE]?.title,
    [item]
  )

  return (
    <li className={classes.root} style={{ marginLeft: indent }}>
      <div
        className={clsx(
          classes.tree,
          !item.children?.length && classes.withoutChildren
        )}
      >
        {item.children?.length ? (
          <div className={classes.toggle} onClick={handleToggle}>
            {open ? <Remove /> : <Add />}
          </div>
        ) : null}
        <NavLink
          to={editPath.replace(':id', item[process.env.REACT_APP_RESOURCE_ID])}
          className={classes.link}
          activeClassName={classes.active}
          exact={true}
        >
          <ListItem button className={classes.inner}>
            <div>
              {title
                ? title.length > 20
                  ? `${title.slice(0, 20)}..`
                  : title
                : translate('T_GENERAL_MISSING_TITLE')}
            </div>
          </ListItem>
        </NavLink>
      </div>
      {item.children?.length ? (
        <ul style={open ? {} : { display: 'none' }}>
          {item.children.map(child => (
            <PageTree
              item={child}
              editPath={editPath}
              indent={indent}
              key={child['@id']}
            />
          ))}
        </ul>
      ) : null}
    </li>
  )
}

const basicShape = {
  '@id': PropTypes.string.isRequired,
  [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  translations: PropTypes.shape({
    [process.env.REACT_APP_LOCALE]: PropTypes.shape({
      title: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

const itemShape = {
  ...basicShape,
  children: PropTypes.arrayOf(PropTypes.shape(basicShape)),
}

PageTree.propTypes = {
  item: PropTypes.shape(itemShape).isRequired,
  editPath: PropTypes.string.isRequired,
  indent: PropTypes.number,
}
